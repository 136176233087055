.video-container {
  width: 100%;
  max-width: 1000px; /* Ajusta el ancho del contenedor del video según sea necesario */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  height: auto;
}

video {
  width: 100%;
  height: auto;
}

.custom-button {
  background-color: #04bb73;
  color: white;
  border-color: #04bb73;
}

.div-segmented{
  width: 200px;
}

.title-subtitle-consulta-aval{
  font-size: 18px;
}

@media screen and (max-width:800px) {
      .title-steps-buro{
      font-size: 28px;
    }
}


@media screen and (max-width:636px) {
  .div-segmented{
    width: 150px;
  }

  .title-steps-buro{
    font-size: 25px;
  }

  .title-subtitle-consulta-aval{
    font-size: 15px;
  }
}

@media screen and (max-width:570px) {

  .title-steps-buro{
    font-size: 22px;
  }

  .title-subtitle-consulta-aval{
    font-size: 12px;
  }
}