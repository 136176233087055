.form-steps-aval{
  max-width: 500px;
}


.select-steps-clientes {
    width: 250px;
    margin-left: 0px;
  }

  .select-steps-ocupacion{
    width: 485px;
  }

  .lblOptionCombOcupacion{
    width: 400px;
  }

  .tooltip-ocupacion{
    margin-left: -100px;
    width: 500px;
    margin-top: -50px;
  }


  .div-segmented{
    width: 200px;
    font-size: 20px;
  }

  .css-dev-only-do-not-override-5hblst.ant-segmented .ant-segmented-item-selected {
    color: #fff;
  }

  @media screen and (max-width:700px) {
    .div-segmented{
        width: 150px;
        font-size: 15px;
      }    
}

  
  @media screen and (max-width:600px) {
    .form-steps-aval{
      max-width: 400px;
    }
  }


  
  
  @media screen and (max-width:600px) {

    .form-steps-aval{
      max-width: 300px;
    }

    .search-steps-cols-aval{
      width: 130px;
      min-width: 100px;
    }

    .select-steps-ocupacion{
      width: 280px;
    }

    .lblOptionCombOcupacion{
      width: 280px;
    }
  }