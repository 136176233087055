.div-condusef-title{
    font-size: 20px;
}

.div-condusef-text{
    font-size: 16px;
    text-align: justify;
}

.div-collapse-title{
    font-size: 20px;
}