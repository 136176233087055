.three-size-collage {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dos columnas */
  gap: 30px; /* Espacio entre imágenes */
}

.collage-item {
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-content: flex-end;
}

.collage-item.large {
  grid-column: span 2; /* Imágenes grandes ocupan ambas columnas */
  height: 300px;
}

.collage-item.medium {
  height: 480px; /* Tamaño intermedio */
  grid-row: span 1;
}

.collage-item.small {
  height: 250px;
  max-width: 300px;
  grid-row: span 2;
}

.collage-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contenedor-imagen {
  position: relative;
}

.fondo {
  background-image: url('../asset/images/img51.jpeg');
  background-size: cover;
  width: 100%;
  overflow: auto;
}

.fondo-footer {
  background-image: url('../asset/images/img51.jpeg');
  background-size: cover;
  width: 100%;
  height: 350px;
}

.overlay-imagen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 20, 52, 0.8);
  display: flex;
  align-items: center;
  justify-content: baseline;
  flex-direction: column;
}

.div-sucursales {
  background: linear-gradient(
    to bottom,
    rgb(255, 255, 255) 0%,
    rgba(162, 167, 167, 0) 50%,
    rgba(217, 223, 236, 0.9) 100%
  );
}

.div-sucursales-card {
  background-color: white;
  height: 80px;
  width: 300px;
  border-radius: 50px;
  box-shadow: 0px 0px 22px -7px rgba(0, 0, 0, 0.75);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
}

.logo-footer {
  width: 250px;
}

.div-footer-adress {
  font-size: 17px;
}

.footer-link {
  color: white;
  font-size: 18px;
}

.div-privacidad-text {
  font-size: 16px;
  text-align: justify;
}

.div-privacidad-title {
  font-size: 20px;
}

.contenedor-nosotros {
  position: relative;
}

.fondo-nosotros {
  background-image: url('../asset/images/Nosotros1.jpg');
  background-size: cover;
  width: 100%;
  height: 680px;
}

.overlay-nosotros {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 50, 0.5) 0%,
    rgba(0, 0, 50, 0) 20%,
    rgba(0, 0, 50, 0.9) 80%
  );
}

.font-title-nosotros {
  font-size: 45px;
  margin-left: 50px;
  width: 90%;
}

.font-title-nosotros2 {
  font-size: 23px;
  margin-left: 50px;
  width: 90%;
  margin-top: -20px;
}

.font-title-nosotros3 {
  font-size: 19px;
  margin-left: 50px;
  width: 60%;
  margin-top: 20px;
}

.input-nosotros {
  width: 35%;
}

.fondo-nosotros-equipo {
  background-image: url('../asset/images/bg-equipo.jpg');
  background-size: cover;
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat;
  width: 100%;
  height: 780px;
}

.overlay-nosotros-equipo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 50, 0.9) 10%,
    rgba(0, 0, 50, 0.5) 40%,
    rgba(0, 0, 50, 0) 100%
  );
}

.font-title-nosotros-equipo {
  font-size: 50px;
  width: 100%;
}

.font-title-nosotros2-equipo {
  font-size: 22px;
  width: 70%;
  margin-top: -20px;
}

.fondo-nosotros-sucursales {
  background-image: url('../asset/images/sucursales.png');
  background-size: cover;
  height: 500px;
}

.fondo-nosotros-entidades {
  background-image: url('../asset/images/Entidades.png');
  background-size: cover;
  height: 500px;
}

.div-sucursales-card-icon {
  font-size: 25px;
  color: #0c0c54;
}

.div-logos-entidades {
  height: 100px;
}

.div-logos-entidades2 {
  height: 140px;
}

.div-logo-links {
  width: 700px;
  margin-top: 40px;
}

@media screen and (max-width: 1500px) {
  .div-sucursales-card {
    width: 250px;
  }
}

@media screen and (max-width: 1300px) {
  .div-sucursales-card {
    width: 200px;
  }
}

@media screen and (max-width: 1100px) {
  .div-sucursales-card {
    width: 150px;
    font-size: 15px;
  }

  .fondo-footer {
    height: 300px;
  }
}

@media screen and (max-width: 999px) {
  .font-title-nosotros2-equipo {
    font-size: 18px;
    width: 90%;
  }
  .collage-item.medium {
    height: 380px; /* Tamaño intermedio */
    grid-column: span 2;
  }
}

@media screen and (max-width: 780px) {
  .div-sucursales-card {
    width: 110px;
    font-size: 12px;
  }

  .div-logo-links {
    width: 500px;
  }
}

@media screen and (max-width: 670px) {
  .div-sucursales-card {
    width: 80px;
    font-size: 10px;
  }

  .div-sucursales-card-icon {
    font-size: 15px;
  }

  .div-logos-entidades {
    height: 60px;
  }

  .div-logos-entidades2 {
    height: 80px;
  }
}

@media screen and (max-width: 600px) {
  .font-title-nosotros {
    font-size: 29px;
    margin-left: 15px;
    margin-bottom: 40px;
  }

  .font-title-nosotros2 {
    font-size: 15px;
    margin-left: 15px;
  }

  .font-title-nosotros2-equipo {
    font-size: 16px;
  }

  .div-logo-links {
    width: 450px;
  }

  .fondo-footer {
    height: 410px;
  }

  .font-title-nosotros-equipo {
    font-size: 35px;
  }
}

@media screen and (max-width: 450px) {
  .div-logos-entidades {
    height: 40px;
  }

  .div-logos-entidades2 {
    height: 60px;
  }

  .div-logo-links {
    width: 300px;
  }

  .footer-link {
    font-size: 13px;
  }
}
