.account-welcome-btn-login {
  width: 200px;
}

.account-welcome-btn-create {
  width: 200px;
}

.div-carousel {
  display: block;
}

.div-panel-picture {
  width: 300px;
  background-color: rgb(252, 253, 253);
  margin-top: 28px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
  height: 794px;
}

.tab-panel-picture {
  width: 300px;
}

@media screen and (max-width: 850px) {
  .div-panel-picture {
    display: none;
  }

  .tab-panel-picture {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .account-welcome-btn-login {
    width: 200px;
  }

  .account-welcome-btn-create {
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .div-carousel {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b6b6b6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7e7b7b;
}
