/* VISTA DOMICILIO LOCALIZACION */

.form-steps-domicilio{
  max-width: 500px;
}

.subtitle-localizacion {
    width: 525px;
    font-size: 20px;
    font-weight: bold;
    margin-left: -10px;
  }

  @media screen and (max-width:800px) {
    .form-steps-domicilio{
        max-width: 400px;
      }

      .subtitle-localizacion {
        width: 400px;
        font-size: 17px;
      }

  }

  @media screen and (max-width:600px) {
    .subtitle-localizacion {
        width: 200px;
        font-size: 15px;
        font-weight: bold;
      }

      .form-steps-domicilio{
        max-width: 300px;
      }
  }