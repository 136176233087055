/* CardCarousel.css */

.card-carousel {
  /* Ajusta los márgenes para centrar la primera tarjeta */
  margin: 0 auto;
  max-width: 1000px;
  /* Ajusta el ancho máximo según tu diseño */
}

.carousel-card {
  /* Ajusta el margen para dar más espacio entre las tarjetas */
  margin: 0 40px;
}

.card-content {
  font-size: 13px;
  height: 110px;
  width: 210px;
  margin-left: -10px;
}

.div-dashboard {
  padding-left: 100px;
  padding-right: 100px;

}

.div-img-arrendamiento{
  background-image: url('../../asset/images/FONDO_DASHBOARD.png');
  background-size: cover;
  width: 60%;
}

.div-img-dashboard-font{
  font-size: 36px;
  color: white;
}

.div-img-dashboard-button{
  height: 50px;
  width: 230px;
  font-size: 18px;
}

@media screen and (max-width:1000px) {
  .div-dashboard {
    padding-left: 0px;
    padding-right: 30px;
  }

}

@media screen and (max-width:800px) {
  .div-dashboard {
    padding-left: 200px;
    padding-right: 200px;
  }

}

@media screen and (max-width:730px) {
  .div-dashboard {
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media screen and (max-width:630px) {
  .div-dashboard {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width:600px) {
  .div-dashboard {
    padding-left: 10px;
    padding-right: 10px;
  }

  .carousel-card {
    /* Ajusta el margen para dar más espacio entre las tarjetas */
    margin: 0 15px;
  }
}