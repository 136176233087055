body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  .ant-segmented-item {
    transition: red 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-segmented-item-selected {
    background-color: red;
  }
  
  .ant-segmented-thumb {
    background-color: red;
  }