/* VISTA CLIENTES */
.select-steps-clientes {
    width: 485px;
    margin-left: -0px;
    min-width: 200px;
}

.datepicker-clientes {
    width: 100%;
    margin-left: 0px;
}

.lblOptionCombo{
    text-align: left;
    width: 200px;
}

.ant-popover-buttons .ant-btn-primary.ant-btn {
    display: none;
  }

.img-revision{
    width: 400px;
}

.img-ine{
    width: 350px;
    height: 500px;
}

.div-card-cliente{
    width: 1200px;
    margin-left: -80px;
}

.custom-datepicker-input {
    width: 100%;
  }


  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #ffffff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
    z-index: 99;
  }

  .ant-radio-button-wrapper-checked {
    background-color: black;
    color: white;
  }

  .css-dev-only-do-not-override-5hblst.ant-segmented .ant-segmented-item-selected {
    color: #fff;
  }


  .div-segmented{
    width: 200px;
  }

@media screen and (max-width:1200px) {
    .div-card-cliente{
        width: 1000px;
        margin-left: -50px;
    }
}

@media screen and (max-width:1050px) {
    .div-card-cliente{
        margin-left: 150px;
    }
}

@media screen and (max-width:900px) {
    .div-card-cliente{
        width: 950px;
        margin-left: 250px;
    }
}

@media screen and (max-width:700px) {
    .div-segmented{
        width: 150px;
      }    

      .title-steps{
        font-size: 28px;
      }
}


@media screen and (max-width:600px) {
    .select-steps-clientes {
        width: 250px;
        margin-left: 0px;
        min-width: 100px;
    }

    

    .img-revision{
        width: 300px;
        margin-left: -50px;
    }
}