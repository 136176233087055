.input-cambio{
    width: 450px;
}

.div-card-cambio{
    background-color: '#ffffff'; margin-top: 28px; border-radius: 15px; box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
}

@media screen and (max-width:600px) {
    .input-cambio{
        width: 300px;
        margin-left: 20px;
        margin-right: 20px;
    }
  
  }