.contenedor-imagen {
    position: relative;
  }

  .fondo {
    background-image: url('../asset/images/img5.jpeg');
    background-size: cover;
    width: 100%;
    height: 430px;
  }
  
  .overlay-imagen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(4, 20, 52, 0.8); 
    display: flex;
    align-items: center;
    justify-content:baseline;
    flex-direction:column;
  }

  .logo-footer{
    width: 250px;
  }

  .div-footer-adress{
    font-size: 17px;
  }

  .footer-link{
    color: white;
    font-size: 20px;
  }

  .div-condiciones-title{
    font-size: 20px;
}

.div-condiciones-text{
    font-size: 16px;
    text-align: justify;
}

.div-condiciones-title{
    font-size: 20px;
}