.div-create-subtitle {
  font-size: 20px;
  display: block;
  margin-left: 100px;
}

.input-create {
  width: 220px;
}

.input-create-one {
  width: 450px;
  margin-left: 0px;
}

.input-create-one-password {
  width: 450px;
  margin-left: -3px;
}

.table-create {
  min-width: 600px;
  background-color: '#ffffff';
  margin-top: 28px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
}

.div-panel-picture {
  width: 300px;
  background-color: rgb(252, 253, 253);
  margin-top: 28px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
  height: 794px;
}

.tab-panel-picture {
  width: 300px;
}

@media screen and (max-width: 1100px) {
  .tab-panel-picture {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .table-create {
    min-width: 480px;
  }

  .input-create {
    width: 200px;
  }

  .input-create-one {
    margin-left: 0px;
    width: 410px;
  }

  .input-create-one-password {
    margin-left: 0px;
    width: 410px;
  }
}

@media screen and (max-width: 600px) {
  .div-create-subtitle {
    font-size: 20px;
    display: none;
    margin-left: 0px;
  }

  .input-create {
    width: 160px;
  }

  .input-create-one {
    margin-left: -3px;
    width: 331px;
  }

  .input-create-one-password {
    margin-left: -6px;
    width: 331px;
  }
}
