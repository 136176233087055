.input-login {
  width: 450px;
}

.div-login-card {
  background-color: '#ffffff';
  margin-top: 28px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
}

.div-panel-picture {
  width: 300px;
  background-color: rgb(252, 253, 253);
  margin-top: 28px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -1px 10px 0px;
  height: 794px;
}

.tab-panel-picture {
  width: 300px;
}

.divTitulo {
  font-size: 50px;
  font-weight: bold;
  color: #4c4cbc;
}

.divSubtitulo {
  font-size: 25px;
  font-weight: bold;
  width: 70%;
}

.divSubtitulo2 {
  font-size: 25px;
  width: 70%;
}

.imgTitulo {
  width: 250px;
}

@media screen and (max-width: 1100px) {
  .tab-panel-picture {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .input-login {
    width: 200px;
  }

  .divTitulo {
    font-size: 40px;
  }

  .divSubtitulo {
    font-size: 20px;
  }

  .divSubtitulo2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 450px) {
  .divTitulo {
    font-size: 35px;
  }

  .divSubtitulo {
    font-size: 16px;
  }

  .divSubtitulo2 {
    font-size: 16px;
  }

  .imgTitulo {
    width: 180px;
  }
}
