
.form-steps-contacto{
    max-width: 500px;
  }
  
  .buttons-contacto{
    width: 100%;
  }

    @media screen and (max-width:800px) {
      .form-steps-contacto{
          max-width: 400px;
        }
  
        .buttons-contacto{
            width: 90%;
          }
  
    }
  
    @media screen and (max-width:600px) {
        
      .subtitle-contacto {
          width: 200px;
          font-size: 15px;
          font-weight: bold;
        }
  
        .buttons-contacto{
            width: 70%;
          }
    }