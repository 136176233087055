.div-title-buro-aval{
    font-size: 37px;
}

.div-buro-aval{
    font-size: 18px;
}

@media screen and (max-width:570px) {
    .div-buro-aval{
        font-size: 14px;
    }
    
    .div-title-buro-aval{
        font-size: 21px;
    }
}