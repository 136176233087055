.form-steps-empresa{
    max-width: 500px;
  }

  .buttons-empresa{
    width: 100%;
  }

  @media screen and (max-width:800px) {
    .form-steps-empresa{
        max-width: 400px;
      }

      .buttons-empresa{
        width: 90%;
      }
  }

  @media screen and (max-width:600px) {
    .form-steps-empresa{
        max-width: 300px;
      }
      .buttons-empresa{
        width: 70%;
      }
  }